.about-us-intro {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #333;
}

.about-us-text-clublemarais {
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: left;
    color: #333;
}

.about-us-container h1,
.about-us-container h2 {
    font-weight: bold;
    color: #000;
}

.about-us-container img {
    width: 100%;
    height: auto;
    object-fit: cover;
}
