.membership-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    text-shadow: black;
    box-shadow: 5px;
}

.membership-promote {
    font-family: 'TitleFont', Arial, sans-serif;
    text-align: center;
    font-size: small;
    color: rgba(47, 129, 253, 0.87);
    margin-bottom: 20px !important;
}

.membership-intro {
    margin: 10px;
    min-width: 40vw;
}

.membership-level-div {
    margin-top: 25px;
}

.membership-card {
    background-image: url('../../public/profolio/membership-bg.webp');
    background-size: cover;
}

.membership-level-title {
    font-family: 'TitleFont', sans-serif;
    margin-bottom: 3px !important;
}

.text-with-shadow {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5),
                 0 0 20px rgba(0, 0, 0, 0.4)
}

.membership-title.gold {
    color: #f1d77f;
}
.membership-button-gold {
    background: linear-gradient(135deg, black, rgb(66, 56, 0), black);
}
.membership-button-gold:hover {
    background: linear-gradient(135deg, black, rgb(102, 87, 0), black);
}

.membership-title.platinum {
    color: #e5e4e2;
}
.membership-button-platinum {
    background: linear-gradient(135deg, black, #50504e, black);
}
.membership-button-platinum:hover {
    background: linear-gradient(135deg, black, #747472, black);
}

.membership-title.diamond {
    color: #b9f2ff;
}
.membership-button-diamond {
    background: linear-gradient(135deg, black, #465b5f, black);
}
.membership-button-diamond:hover {
    background: linear-gradient(135deg, black, #6d8d94, black);
}

.membership-benefits li {
    text-align: left;
    font-size: 1rem;
    line-height: 1.6;
}

.membership-card {
    border: none;
    border-radius: 12px;
}

.membership-card:hover {
    transform: scale(1.03);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
}

.membership-card-display {
    max-height: 40vh !important;
}
