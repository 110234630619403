/* Define Inter font family */
@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Regular.ttf') format('truetype');
    font-weight: 400;
    /* Normal weight */
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Bold.ttf') format('truetype');
    font-weight: 700;
    /* Bold weight */
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    /* Light weight */
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('../../public/fonts/Inter-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TitleFont';
    src: url('../../public/fonts/HeidanRounded-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'LogoFont';
    src: url('../../public/fonts/Chopinscript.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
